@import "../../styles/index.scss";

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxPagination {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: flex-end;

  .viewing {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $gray-light-chocolate;
  }

  .container {
    @extend .flexCenter;
    margin-bottom: 0;

    & > li {
      list-style: none;
    }
  }

  .previous,
  .next {
    @extend .flexCenter;
    width: 23px;
    height: 20px;
    padding: 0 4px;
    text-align: center;

    & > a {
      @extend .flexCenter;
      text-decoration: none;
      font-weight: 500;
      color: $milk-chocolate;
    }
  }

  .disabled > a {
    color: $gray-light-chocolate;
    cursor: default;
  }

  .active,
  .page:hover {
    @extend .flexCenter;
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);
    border: none;
    color: $ruby-chocolate !important;
    position: relative;
    width: 30px;
    height: 30px;
    font-weight: 700;
    font-size: 12px;
    background: white;
    border-radius: 2px;
    margin-left: 5px;
  }

  .page {
    color: $milk-chocolate;
    position: relative;
    width: 30px;
    height: 30px;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;

    & > a {
      @extend .flexCenter;
      width: 100%;
      height: 100%;
    }
  }
}
