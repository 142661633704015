@import "/src/styles/colors.scss";

.defaultTable {
  margin-top: 2%;
  overflow-x: auto;

  table {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
  }

  thead > tr {
    th {
      border-top: 1px solid var(--bs-border-color);
    }

    th:not(:first-child, :last-child) {
      border-left-width: 0;
    }

    & > th:first-child {
      border-top-left-radius: 16px;
    }

    & > th:last-child {
      border-top-right-radius: 16px;
      border-left-width: 0;
    }
  }

  tbody > tr {
    & > td {
      border: 1px solid var(--bs-border-color);
      border-bottom-width: 0;
    }

    &:last-child {
      & > td {
        border-bottom-width: 1px;
      }
    }

    td:not(:first-child, :last-child) {
      border-left-width: 0;
    }

    & > td:last-child {
      border-left-width: 0;
    }
  }

  td {
    padding: 10px 15px;
    color: $dark-chocolate;
    line-height: 120%;
    font-size: 14px;
  }

  th {
    font-size: 13px;
    color: $gray-mid;
    background-color: $almost-white;
    padding: 12px 15px;
    vertical-align: middle;
    white-space: nowrap;
  }

  thead {
    box-shadow: inset 0px -1px 0px #d2d3d5;
  }
}
