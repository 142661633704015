@import "../../../styles/index.scss";

.userTabDetail {
  margin-top: 50px;
  margin-inline: -30px;
  padding-inline: 26px;

  & > li {
    --bs-nav-link-color: #{$dark-chocolate};
    --bs-nav-tabs-link-active-color: #{$ruby-chocolate};
    margin: 0 5px;

    &[role="presentation"] {
      flex: none;
    }

    & > button {
      --bs-nav-tabs-border-radius: 10px;
      --bs-nav-link-hover-color: #{$dark-chocolate};

      border: 1px solid #{$grey-lightest} !important;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }

    & > button:hover {
      color: #{$ruby-chocolate};
    }
  }
}

.listGroupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $gray-mid-chocolate;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.loader {
  margin-top: 20px;
}
