@import "../../../styles/index.scss";
.modalTitle {
  color: $dark-chocolate;
  font-weight: bold;
  font-size: 26px;
  line-height: 31.8px;
}

.modalDescription {
  color: $dark-chocolate;
  font-size: 14 px;
  line-height: 21px;
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 15px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 136px;
  justify-content: space-between;
  .aliasContent span {
    font-size: 13px;
    line-height: 19.5px;
    padding-top: 4px;
    font-weight: 400;
    color: $red-error;
  }
}

.accountInfo {
  color: $dark-chocolate;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 5px;
}

.modalContainer {
  display: flex;
  padding: 30px 28px;
  flex-direction: column;
}

.submitButton {
  padding: 12px 30px;
  border-radius: 30px;
  background-color: $ruby-chocolate;
  border: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-right: 3px;
  &:hover {
    background-color: $ruby-chocolate !important;
    opacity: 0.65;
  }
  &:disabled {
    background-color: $gray-lightest-chocolate !important;
  }
}

.cancelButton {
  padding: 14px 30px;
  border-radius: 30px;
  background-color: white;
  color: $ruby-chocolate;
  border: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-left: 3px;
  &:hover {
    background-color: white !important;
    color: $ruby-chocolate !important;
    opacity: 0.65;
  }
}

.modalFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
