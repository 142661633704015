@import "./colors.scss";

.container {
  position: relative;
}

.table {
  margin-top: 2%;
  th {
    text-align: center;
  }
  td {
    text-align: center;
  }

  tr.tableRow:hover {
    cursor: pointer;
  }
}

.pageTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: $dark-chocolate;
}

.userListTablePagination {
  position: absolute;
  top: -72px;
  right: 0;
}
