@import "/src/styles/colors.scss";
@import "/node_modules/bootstrap/scss/functions";
.badgeCommon {
  font-weight: 600;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  width: max-content;
  &.resubmissionRequired {
    width: min-content;
    white-space: pre-wrap;
    text-align: start;
  }
}

.danger {
  @extend .badgeCommon;
  --bs-error-rgb: #{to-rgb($red-error)};
  color: $white;
}

.success {
  @extend .badgeCommon;
  --bs-success-rgb: #{to-rgb($green-light)};
  color: $green-success;
}

.secondary {
  @extend .badgeCommon;
  --bs-secondary-rgb: #{to-rgb($almost-white)};
  color: $dark-gray;
}

.warning {
  @extend .badgeCommon;
  --bs-warning-rgb: #{to-rgb($pale-chocolate)};
  color: $dark-chocolate;
}

.danger-outline {
  @extend .badgeCommon;
  color: $red-error;
  background-color: $pink-light;
}
