@import "/src/styles/colors.scss";
.inputWrap {
  width: auto;
  height: auto;
  position: relative;
  input[type="text"] {
    border: 1px solid $gray-lightest-chocolate;
    border-radius: 5px;
    height: 56px;
    width: 465px;
    color: $dark-chocolate;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 56px;
    box-shadow: none;
    &:focus {
      outline: none;
      border-color: $ruby-chocolate !important;
    }
  }
  input:focus + label,
  input:valid + label {
    font-size: 12px;
    color: $dark-chocolate;
    font-weight: 600;
    top: -5px;
    left: 10px;
    background: #ffffff;
    padding: 0px 5px 0px 5px;
  }
  label {
    font-size: 16px;
    color: $gray-light-chocolate;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
  }
  .clearIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    cursor: pointer;
  }
}

.filledOutline {
  outline: none;
  border-color: $white-chocolate !important;
}
