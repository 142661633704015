@import "./index.scss";
.card {
  width: 20rem;
  margin-top: 20px;
  text-align: center;
}

.cardImage {
  border-radius: 50%;
  border: #8080802e 2px solid;
  margin: auto;
  margin-top: 5px;
  width: 18rem;
}

.cardText {
  display: flex;
  flex-direction: column;
}

.cardTextItem {
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  font-size: 14px;
  line-height: 150%;
  color: $dark-chocolate;
}

.cardTextItem b {
  font-weight: 700;
}

.cardTextItemBorderless {
  margin-bottom: 10px;
  display: flex;
  padding: 5px;
  text-overflow: ellipsis;
}

.cardTextItemContentContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
}

.mambuLink {
  color: #000000;
  text-decoration: none;
}

.widthFitWithContentLength {
  min-width: max-content;
}

.cardMambuDetails {
  border-radius: 10px;
  border: 1px solid black;
  margin-bottom: 10px;
  margin-top: 20px;
}

.wrapper {
  .backToUserLink {
    color: $ruby-chocolate;
    font-weight: 700;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 30px;
    & > span {
      font-size: 16px;
      margin-bottom: 1px;
    }
  }
  .boxTitleUserDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleRight {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $ruby-chocolate;
      text-decoration: none;
      cursor: pointer;
      img {
        margin-left: 5px;
      }
    }
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.listGroupTitle {
  text-align: center;
  font-size: 18px;
  font-style: bold;
}

.textArea {
  height: 247px;
  width: 100%;
}

.editIcon {
  margin-left: 5px;
  margin-right: 5px;
}

.detailsCard {
  margin-top: 20px;
  border: none;
}

.dynamicTable {
  overflow: auto;
  height: 390px;
}

.statusesTable {
  margin-top: 5%;
}

.tableLoader {
  margin: auto;
  margin-top: 6%;
}

.listGroupCustomTitle {
  font-size: 20px;
  font-weight: 500;
}

.titleUserDetail {
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: $dark-chocolate;
}

.titleUserDetailRightBox {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.titleInBox {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $gray-mid;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & > img {
    margin-right: 5px;
  }
}

.disbledUser {
  color: #433939;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-weight: 600;
}

.boxUserDetail {
  padding: 30px;
  border: 1px solid $grey-lightest;
  border-radius: 10px;
  margin-top: 30px;
  .mambuLink {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: $espresso-chocolate;
    word-break: break-all;
  }
}

.copyToClipboard {
  margin-bottom: 20px;
  color: $dark-chocolate;
  span {
    margin-right: 12px;
  }
  img {
    cursor: pointer;
    padding-bottom: 8px;
  }
}
