@import "/src/styles/colors.scss";

.filter-container {
  margin-left: 71px;

  & > div > div > a {
    --bs-dropdown-link-color: #{$dark-chocolate};
    --bs-dropdown-link-hover-color: #{$dark-chocolate};
  }

  .dropdownMenuItem {
    max-height: 50vh;
    overflow: auto;
  }
}

.filter-input {
  padding: 10px 20px;
  margin: 10px 5px;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  width: 30%;
}

.divider {
  background-color: $almost-white;
}

.dropdown {
  padding-left: 0px;
  margin: 10px 0px;
  width: unset;
}

.selected-filter-badge {
  width: fit-content;
  align-items: center;
  margin: auto 0;
  height: 10%;
  display: flex;
}

.selected-filter-badge-wrapper {
  height: 100%;
  width: 30px;
  padding-top: 20px;
}

.selected-filters {
  width: fit-content;
}

.selected-filters-wrapper {
  font-weight: bold;
  padding: 20px 0;
}

.select-value-dropdown {
  display: inline;

  .dropdown-item:active {
    --bs-dropdown-link-active-color: #{$white};
    --bs-dropdown-link-active-bg: #{$dark-gray};
  }

  ::after {
    display: none;
  }

  .btn.search-toggle {
    --bs-btn-hover-bg: $white;
    --bs-btn-hover-color: $dark-chocolate;

    max-width: 200px;
    color: $dark-chocolate;
    background-color: $white;
    border: 0;
    z-index: 0;
    border-width: 1px;
    border-color: $grey-lightest;
    border-style: solid;
    border-radius: 0px 4px 4px 0px;
    border-left: none;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-size: 14px;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .material-icons {
      transform: rotate(90deg);
      color: $gray-light;
    }
  }

  .dropdown-menu {
    min-width: unset;
    width: 282px;

    & > a {
      padding: 8px 10px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -1%;
      white-space: pre-wrap;
    }
  }

  .disable-btn {
    background-color: $almost-white !important;

    & > .material-icons,
    & > span {
      color: $grey-lightest !important;
    }
  }
}

.add-filters-dropdown {
  display: inline;

  .dropdown-item:active {
    --bs-dropdown-link-active-color: #{$white};
    --bs-dropdown-link-active-bg: #{$dark-gray};
  }

  ::after {
    display: none;
  }

  .btn.search-toggle {
    --bs-btn-hover-bg: $white;
    --bs-btn-hover-color: $dark-chocolate;

    width: 196px;
    color: $dark-chocolate;
    background-color: $white;
    border: 0;
    z-index: 0;
    border-width: 1px;
    border-color: $grey-lightest;
    border-style: solid;
    border-radius: 4px 0px 0px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-size: 14px;
      line-height: 21px;
    }

    & > .material-icons {
      transform: rotate(90deg);
      color: $ruby-chocolate;
    }
  }

  .dropdown-menu {
    min-width: unset;
    width: 196px;

    & > a {
      padding: 8px 10px;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.title-text {
  color: $gray-light;
  letter-spacing: -1%;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.nowrap {
  flex-wrap: nowrap;
}

.input-group {
  max-width: 330px;
  padding: 0px;
}

.search-input {
  max-width: 200px;
  border-width: 1px;
  border-color: $grey-lightest;
  border-style: solid;
  border-radius: 0px 4px 4px 0px;

  .search-icon {
    width: unset;
    max-width: unset;
    font-size: 20px;
    padding: 0px;
    margin-right: 5px;
    margin-left: 5px;
    color: $gray-light;
  }

  .form-control:focus {
    box-shadow: none;
    border-color: transparent;
  }

  input {
    border: none;
    font-size: 14px;
    color: $gray-light;
    line-height: 21px;
  }
}

.search-btn {
  padding: 4px 18px;
  margin-left: 8px;
  border-radius: 30px;
  background-color: $ruby-chocolate;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  max-width: 86px;

  &:hover {
    background-color: $ruby-chocolate !important;
    opacity: 0.65;
  }
}

.search-dropdown {
  margin: 0;

  .dropdown-item:active {
    --bs-dropdown-link-active-color: #{$white};
    --bs-dropdown-link-active-bg: #{$dark-gray};
  }

  ::after {
    display: none;
  }

  .btn.search-toggle {
    --bs-btn-hover-bg: $white;
    --bs-btn-hover-color: $dark-chocolate;

    width: 130px;
    color: $dark-chocolate;
    background-color: $white;
    border: 0;
    z-index: 0;
    border-width: 1px;
    border-color: $grey-lightest;
    border-style: solid;
    border-radius: 4px 0px 0px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -1%;
    }

    & > .material-icons {
      transform: rotate(90deg);
      color: $ruby-chocolate;
    }
  }

  .dropdown-menu {
    min-width: unset;

    & > a {
      padding: 8px 10px;
      width: 130px;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.search-result {
  margin-left: 0px;
  margin-top: 40px;
  width: fit-content;
  padding: 8px 16px;
  background-color: $pale-chocolate;
  border-radius: 8px;

  & > span {
    max-width: fit-content;
    width: fit-content;
    font-size: 14px;
    line-height: 21px;
    padding: 0px 28px 0px 0px;
  }

  & > .clear-btn {
    max-width: fit-content;
    width: fit-content;
    cursor: pointer;

    & > .material-icons {
      max-width: fit-content;
      width: fit-content;
      padding: 0px;
      color: $ruby-chocolate;
      margin-right: 6px;
    }
    & > span {
      font-size: 13px;
      line-height: 20px;
      color: $ruby-chocolate;
      font-weight: 700;
      padding: 0px;
      max-width: fit-content;
      width: fit-content;
    }
  }
}
