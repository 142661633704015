@import "../../styles/index.scss";
@import "/src/styles/colors.scss";

.container {
  min-width: 1200px;

  .logo {
    width: 121px;
    height: 32px;
  }

  .outletContainer {
    padding: 60px;
  }

  .navContainer {
    padding: 0px 60px;
    color: $dark-chocolate;
    width: 100%;
    height: 74px;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 6px 20px rgba(18, 18, 18, 0.08);

    .navLink {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $dark-chocolate;
      border-radius: 0.25rem;
      padding: 0.5rem;
      margin: 0.5rem;
      transition: all ease-in-out 100ms;
      white-space: nowrap;
      font-size: 14px;

      &:hover {
        color: $white;
        background-color: $ruby-chocolate;
      }

      & > span {
        font-size: 15px;
      }

      .navLinkLabel {
        margin: 0 0 0 10px;
        font-weight: bold;
      }
    }

    .navList {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-left: 80px;
      align-items: center;

      .navListLabel {
        font-size: 14px;
        color: $dark-chocolate;
        font-weight: bold;
        margin: 0;
        text-decoration: none;

        & > p {
          margin: 0;
        }
      }
    }
  }
}
