$white: #ffffff;
$dark-chocolate: #3d1912;
$ruby-chocolate: #e42082;
$gray-mid-chocolate: #6e7177;
$gray-lightest-chocolate: #d2d3d5;
$gray-light-chocolate: #9f9fa5;
$milk-chocolate: #701d1b;
$espresso-chocolate: #914507;
$white-chocolate: #ba894f;
$gray-mid: #6e7177;
$dark-gray: #4e5258;
$grey-lightest: #d2d3d5;
$gray-light: #9f9fa5;
$almost-white: #f8f8f8;
$red-error: #cc1000;
$green-light: #d6f6eb;
$green-success: #009761;
$pale-chocolate: #f1e7dc;
$pink-light: #ffeceb;
