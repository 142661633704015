@import "/src/styles/colors.scss";
@import "/src/styles/table.module.scss";

.boxExternalPagination {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.listGroupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $gray-mid-chocolate;
  display: flex;
  align-items: center;
}

.table {
  @extend .defaultTable;

  margin-top: 0;

  .veriffStationLink {
    color: $espresso-chocolate;
    font-weight: 600;
    font-size: 13px;
  }

  .emptyTable {
    height: 100px;
    border: 1px $grey-lightest solid;
    border-left-width: 1px !important;
    text-align: center;
    vertical-align: middle;
  }

  .emptyTable {
    height: 100px;
    border: 1px $grey-lightest solid;
    border-left-width: 1px !important;
    text-align: center;
    vertical-align: middle;
  }
}
