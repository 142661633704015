@import "/src/styles/colors.scss";
@import "/src/styles/table.module.scss";
.boxExternalPagination {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  .aliasGeneralInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.listGroupTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $gray-mid-chocolate;
  display: flex;
  align-items: center;
}

.addBtn {
  padding: 8px 18px;
  margin-left: 8px;
  border-radius: 30px;
  background-color: $ruby-chocolate;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  &:hover {
    opacity: 0.65;
  }
  &:focus-visible {
    background-color: $ruby-chocolate !important;
  }
}

.table {
  @extend .defaultTable;
  margin-top: 0;
  font-size: 14px;
  button {
    border: none;
  }
  .aliasLink {
    color: $ruby-chocolate;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
  .emptyTable {
    height: 100px;
    border: 1px $grey-lightest solid;
    border-left-width: 1px !important;
    text-align: center;
    vertical-align: middle;
  }
  .emptyTable {
    height: 100px;
    border: 1px $grey-lightest solid;
    border-left-width: 1px !important;
    text-align: center;
    vertical-align: middle;
  }
}
