@import "/src/styles/colors.scss";
@import "/src/styles/table.module.scss";

.table {
  @extend .defaultTable;

  margin-top: 20px;

  tr.tableRow:hover {
    cursor: pointer;
  }

  .fullname {
    font-weight: 700;
    font-size: 16px;
  }

  .email {
    font-size: 13px;
    margin-top: 4px;
  }

  .emptyTable {
    height: 100px;
    border: 1px $grey-lightest solid;
    border-left-width: 1px !important;
    text-align: center;
    vertical-align: middle;
  }
}
