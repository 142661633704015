@import "~bootstrap/scss/bootstrap";
@import "./styles/index.scss";
@import "react-notifications-component/dist/theme.css";
@import "react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#disableAccountModal .modal-content {
  --bs-modal-border-radius: 24px;
}
